@import url('https://fonts.googleapis.com/css2?family=Red+Rose&display=swap');

@keyframes anim {
  from {left: -100px;}
  to {left: 0px;}
}

body {
  margin: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.image {
  position:relative;
  height: 500px;
  /* margin-right:5px;
  margin-left:5px; */
 
  /* width: -95%; */
}
.image2 {
  position:relative;
  height: 450px;
  /* width: -90%; */
}



.content {
  font-family: 'Red Rose', sans-serif;
  display: flex;
  /* align-items: center; */
  justify-content:center;

  position:relative;
  /* align-items: right; */
  margin-top: 180px;
  margin-right:30px;
  
  /* margin-bottom:-80px; */
  padding: 40px;
 
  /* change to this to have rly nice resizing
  height:70vh; */

  height: 400px;
  animation-name: anim;
  animation-duration: 1s;

  
}

/* .circle{
  z-index: 0;
  position: absolute;
  height: 500px;
  width: 500px;
  border-radius: 250px;
  background-color:rgba(255, 235, 205, 0.468);
  margin-bottom: 400px;
  margin-left: 500px;
} */
.content span.img-txt {
  /* background-color: rgba(255, 228, 196, 0.442); */
  z-index: 1;
  text-transform:lowercase;
 
  /* color:rgb(41, 67, 69); */
  color:white;
  /* padding: 1rem; */
  margin-right:-40%;

  /* margin-right: 5vh; */
  padding-right:20px;
  font-size: 100px;
  text-align: right;
  /* letter-spacing: 1px; */
  line-height:30px;
  
}
.content span.img-txt .para {
  /* background-color: bisque;  */
  text-transform: lowercase;
  color:white;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 20px;
  line-height:25px;
  
  
}

.button-text {
  color:black;
  /* font-size: 12px; */
}

.content-2{
  /* font-family: 'Lato', sans-serif; */
  display:flex;
  color: white;
  line-height:35px;
  padding: 30px;
  /* margin-top: 5vh; */
  /* margin-bottom:40px; */
  flex-direction: column;
  align-items: center;
  
}
.passions {
  font-size: 30px;
  margin-bottom: 10px;
  
  /* text-align: right; */
}
.passions-2 {
  font-size: 30px;
  line-height:30px;
  /* margin-bottom: 30px; */
  
  /* text-align: right; */
}
.one {
  position: relative;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  /* margin-left:60px; */
  background-color: rgba(0, 255, 255, 0.559);
  color:#011267;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: fit-content;
  border-radius: 40px;
  font-size: 13px;
}
.one a{
  text-decoration: none;
  font-size: 18px;
  color: black;
}
.one a:hover {
  color:blue;
}
.two {
  text-align: right;
  margin: 20px;
  margin-right:60px;
}
.content-2 span.img-txt{
  display: flex;
  justify-content: center;
  z-index: 1;
  text-transform:lowercase;
  
  /* background-color:cadetblue; */
  color:rgb(255, 255, 255);
  /* padding: 1rem; */
  margin-top: 40px;
  padding: 10px;
  margin-left:60px;
  margin-right:60px;
  font-size: 1rem;
  text-align: left;
  letter-spacing: 4px;
  line-height:15px;
  


  
}
.button {
  /* box-shadow: inset 0 0 0 0 #58616b; */
  /* transition: color .5s ease-in, box-shadow .5s ease-in; */
  /* transition-duration: 1s; */
  background-color: #B6C3D0;
  /* width: 50%; */
  padding: 6px;
  margin-top: 20px;
  text-align: center;
  margin-left: 55%;
  font-size: 14px;
  overflow: hidden;
  border-radius: 20px;
  
}

.button:hover {
  /* box-shadow: inset 250px 0 0 0 #4a6f95; */
  background-color: rgb(215, 194, 165);

  cursor: pointer;
  transition: all .35s ease-Out;
}



.navBar {
  position: fixed;
  background-color: rgba(0, 85, 255, 0.247);
  /* background-color: rgba(0, 0, 0, 0.523); */
  /* text-align: left; */
  color:white;
  font-size:20px;
  font-family: 'Red Rose', sans-serif;
  
  padding-top: 15px;
  height: 80px;
  z-index: 3;
  
  width: 100%;
}
.navBar:hover {
  background-color: rgba(169, 183, 215, 0.9) ;
}


.navBar ul {
  list-style-type: none;
  margin: 10px;
}

.navBar li {
  display: inline;
  margin-right:30px;
 
}

.navBar li:hover {
  color:antiquewhite;
  text-shadow: 3px 3px 8px #011267;
  cursor:pointer;
}


.video{
  margin: 10px;
  padding: 30px;
  padding-top:40px;
  border-radius: 20px;
  border: 5px solid rgba(0, 255, 255, 0.559);
  /* background-color: rgba(0, 255, 255, 0.559); */
}

.footer {
  height: 150px;
  width: 100%;
  background-color: black;
}

.foot-text {
  display: flex;
  color: white;
  padding: 40px;
  justify-content:center;
}

.footer img {
  width:100%;
}