
section.slideshow {
    width: 80%;
    margin: 40px;
  }
  section.slideshow div.slide-holder{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
  }
  section.slideshow section.current{
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: auto 5px;
  }
  section.slideshow section.previous,
  section.slideshow section.next{
    width: 70px;
    height: 70px;
  }
  section.slideshow section.slide div.slide-thumbnail{
    width: 100%;
    height: 100%;
    background-color:blanchedalmond;
    background-size: cover;
    background-position: center;
    border-radius: 200px;
    box-shadow: 5px 5px 6px #0000009c;
  }
  section.slideshow div.slideshow-controller{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  section.slideshow div.slideshow-controller span{
    padding: 5px;
    width: 70px;
    border: none;
    background:blanchedalmond;
    color: rgb(30, 55, 56);
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    box-shadow: 3px 3px 5px #00000083;
    user-select: none;
  }
  section.slideshow div.slideshow-controller span:hover{
    color:cadetblue;
    text-shadow: 3px 3px 5px #ffc200;
  }
  section.slideshow div.slideshow-controller span:active{
    color: rgb(30, 55, 56);
    text-shadow: 3px 3px 5px #000000;
    background: #ffc200;
  }