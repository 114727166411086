@import url('https://fonts.googleapis.com/css2?family=Red+Rose&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Red Rose', sans-serif;

}
@media screen and (max-width: 600px){
  .about {
    flex-direction: column;
  }
  .contact {
    flex-direction: column;
  }
}


.about {
  display: flex;
  
  /* max-width: 800px; */
  height: 600px;
  align-items: center;
  justify-content: space-around;
  /* justify-content: center; */
  padding:90px 20px;
  
  /* border: 10px inset black; */
  
  background-color: #E8E5CA;
  color: black;
  font-size: 70px;
  
  /* background-color: rgb(235, 124, 51); */
 
 
}
.about a{
  font-size: 20px;

}



.about p {
  z-index: 1;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  
}
.about-label {
  z-index: 1;
  /* max-width: 300px; */
  text-align: right; 
  margin-left:30px;
  margin-right:30px;
  /* font-style: oblique; */
  /* margin:60px; */
  
  
}
/* this is wear the profile picture comes in */
 .about-circ {
  position: absolute;
  z-index: 0;
  justify-content: space-around;
  align-items: center;
  width: 420px;
  height: 420px;
  
  background: rgba(75, 124, 220, 0.32);
  border-radius: 210px;
}
/* .about-circ img {
  height:150px;
  margin: 25px;
  
} */
.about-content{
  width: 350px;
  min-width: 320px;
  height: 300px;
  justify-content:center;
  padding: 25px;
  /* padding-left: 60px; */
  /* margin: 30px; */
  /* margin-top:10px; */
  margin-left:30px;
  margin-right:30px;
  text-align: left;
  background-color:#FFB774;
  border-radius: 20px;
  color: black;
  /* font-family: 'Lato', sans-serif; */
  /* border: 7px double rgb(255, 255, 255); */
  /* border: 7px double rgb(235, 124, 51); */
  
  
  
  
}

.projects {
  
  /* height: 110vh; */
  align-items: center;
  padding:40px;
  font-size: 70px;
  background-color: #00226754;
  color: white;
}
.projects-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.proj-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 800px;
  color:black;
  margin-top: 40px;
  
}
.projects-content li {
  display: inline;
  /* display: flex; */
  /* flex-direction: row; */
  
  list-style: none;
  
}



.contact-me {
  /* color:rgb(4, 87, 87); */
  color:rgb(4, 87, 87);
  margin-left: 5px;
}
.contact-me:hover {
  color:rgb(59, 186, 200);
}

.ind-proj {
  box-shadow: inset 0 0 0 0 #B6C3D0;
  color:rgb(82, 39, 2);
  transition: color .5s ease-in-out, box-shadow .3s ease-in-out;
  background-color: #FFB774;
  padding: 30px;
  padding-top: 45px;
  margin-bottom: 40px;
  margin-left:20px;
  margin-right:20px;
  border-radius: 125px;
  border: 10px solid #B6C3D0;
  /* border: 5px outset rgb(255, 149, 0); */
  height:250px;
  width:250px;

}

.ind-proj:hover {
  box-shadow: inset 250px 0 0 0 #B6C3D0;
  color: black;
}
.proj-label p {
  font-size: 11px;
  line-height: 2.2ch;

  /* margin-left:10px; */
  text-align: center;
  padding: 10px;
  border-radius: 30px;
}
.proj-name {
  font-size: 14px;
  text-align: center;
}

/* 
.contact textarea {
  max-width: 400px;
  min-width: 200px;
  max-height: 400px;
  min-height: 50px;
}
.contact input{
  margin: 5px;
}
.form {
  background-color:rgba(255, 160, 87, 0.809) ;
  padding: 40px;
  border-radius: 20px;
} */

.contact {
  display: flex;
  justify-content: center;
  background-color: #E8E5CA;
  color: black;
  font-size: 70px;
  padding: 40px;
  height: 600px;
  
  align-items: center;

}
.left-contact {
  z-index: 1;
  /* max-width: 200px; */
  text-align: right;
  line-height: 60px;
  margin: 0px 40px;
  
  
}
.contact a{
  
  font-size: 30px;
  margin-right: 5px;
  color: black;
  
}
.contact a:hover {
  color:blanchedalmond;
}

.right-contact {
  z-index: 1;
  height: 230px;
  width: 350px;
  margin: 0px 40px;
  padding: 20px;
  padding-left: 40px;
  /* margin-bottom: 40px; */
  background-color: #FFB774;
  border-radius: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
}
.right-contact textarea {
  max-width: 250px;
  min-width: 100px;
  max-height: 80px;
  min-height: 50px;
}